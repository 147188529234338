import React, {useState, useEffect} from 'react'
import PhoneInput from 'react-phone-input-2';
import FileBase from 'react-file-base64';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import 'react-phone-input-2/lib/style.css';
import { createClient, updateClient } from '../redux/features/clientSlice';
import HeaderAdmin from '../components/HeaderAdmin';

const initialState = {
  firstName:'',
  lastName:'',
  phone:'',
  email:'',
  birth:'',
  address:'',
  description:'',
  liar:'no'
}

const EditClient = ({goToClients})=> {
    const [clientData, setClientData] = useState(initialState);

    const error = useSelector((state) => state.client.error);
    const clients = useSelector((state) => state.client.clients);

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
useEffect(()=>{
    const singleClient = clients.find((client)=> client._id === id);
    setClientData({...singleClient})
},[])

    const {firstName, lastName, phone, email, birth, address, description} = clientData;

    useEffect(()=>{
    error && toast.error(error)
    },[error])
 
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setClientData({...clientData, [name]: value})
      };
      const handleSubmit = (e) =>{
          e.preventDefault();
            const updatedClientData = {...clientData, name:user?.result?.name}
        dispatch(updateClient({id, updatedClientData, navigate, toast}))  
            handleClear();
          }
      const handleClear = () =>{
        setClientData({ firstName:'', lastName:'', phone:'', email:'', birth:'', address:'', description:'', liar:'no'})
      };

  return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
      {/* Header */}
      <HeaderAdmin />
      <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">Edit Client</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <div className="flex flex-row  w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} value={firstName} id="firstName"  name="firstName" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} value={lastName} id="lastName" name="lastName" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          
            </div>
            <div className="flex flex-row w-full">
            <div className='md:w-1/2 w-full'>
  <label htmlFor="phone" className="block text-sm font-medium text-gray-200">Phone Number</label>
  <input
    type="text"
    id="phone"
    name="phone"
    value={phone}
    onChange={onInputChange}
    className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
    placeholder="+1234567890" // Primer formata
  />
</div>
          <div className='md:w-1/2 w-full mt-1'>
            <label htmlFor="email" className="block text-sm font-medium text-gray-100">Email</label>
            <input onChange={onInputChange} value={email} id="email" name="email" type="text" className=" w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex flex-row w-full">

 <div className='md:w-1/2 w-full mt-1'>
            <label htmlFor="birth" className="block text-sm font-medium text-gray-100">Date of birth</label>
            <input onChange={onInputChange} value={birth} id="birth" name="birth" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
<div className='md:w-1/2 w-full mt-1'>
            <label htmlFor="address" className="block text-sm font-medium text-gray-100">Address</label>
            <input onChange={onInputChange} value={address} id="address" name="address" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex flex-row w-full">
           <div className="w-1/2 mt-1">
             <label htmlFor="description" className="block text-sm font-medium text-gray-100">Description</label>
             <input onChange={onInputChange} value={description} id="description" name="description" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='w-1/2 mt-1'>
    <label htmlFor="liar" className="block text-sm font-medium text-gray-100">Promise-breaker</label>
    <select 
        id="liar" 
        name="liar" 
        value={clientData.liar} 
        onChange={(e) => setClientData({ ...clientData, liar: e.target.value })} 
        className=" w-full mt-1 px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
    >
        <option value="no">No</option>
        <option value="yes">Yes</option>
    </select>
</div>
 </div>
 
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div></div>
  )
}

export default EditClient;