import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
export const createService = createAsyncThunk(
    "service/createService",
    async ({updatedServiceData, navigate, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createService(updatedServiceData);
            toast.success('Service added successfuly');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getUserServices = createAsyncThunk(
    "service/getUserServices",
    async ({userId},{rejectWithValue})=>{
        
        try{
            const response = await api.getUserServices(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const deleteOneService = createAsyncThunk(
    "service/deleteOneService",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteOneService(id);
            toast.success('Service deleted successfuly')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateService = createAsyncThunk(
    "service/updateService",
    async ({id, updatedServiceData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateService(id, updatedServiceData);            
            toast.success('Service updated successfuly');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
const serviceSlice = createSlice({
    name:'service',
    initialState:{
        service:{},
        services:[],
        userServices:[],
        error:"",
        loading:false
    },
   
    extraReducers: (builder) => {
        builder
        .addCase(createService.pending, (state) => {
            state.loading = true;
        })
        .addCase(createService.fulfilled, (state, action) => {
            state.loading = false;
            state.services = [...state.services, action.payload];
            
        })
        .addCase(createService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message 
        })
        .addCase(getUserServices.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserServices.fulfilled, (state, action) => {
            state.loading = false;
            state.services =  action.payload;
            
        })
        .addCase(getUserServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message 
        })
        .addCase(deleteOneService.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteOneService.fulfilled, (state, action) => {
            state.loading = false;
            const {arg : {id},} = action.meta;
            if(id){
              state.services = state.services.filter((item)=> item._id !== id)
            }
        })
        .addCase(deleteOneService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message; 
        })
        .addCase(updateService.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateService.fulfilled, (state, action) => {
            state.loading = false;
            const {arg : {id},} = action.meta;
            if(id){
              state.services = state.services.map((item)=> item._id === id ? action.payload : item)
            }
        })
        .addCase(updateService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message; 
        })
    }
});
export default serviceSlice.reducer;