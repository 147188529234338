import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { register, selectLoading, selectError } from '../redux/features/authSlice';
import Header from '../components/Header';


const initialState = {
  company:'',
  person:'',
  email:'',
  password:'',
  confirm:'',
  country:'',
  city:'',
  address:''
  }

const Register = () => {
  const [formValue, setFormValue] = useState(initialState);
  const {company, person, email, confirm, password, country, city, address} = formValue;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
   const error = useSelector(selectError);

useEffect(()=>{
 error&&toast.error(error)
},[error])

  const handleSubmit = (e) =>{
 e.preventDefault();
if(password !== confirm){
  return toast.error('Password should match')
}
if(company && person && email && password && country && city && address){
  dispatch(register({formValue, navigate, toast}))
}
 }
  const onInputChange = (e) =>{
 let {name, value} = e.target;
 setFormValue({...formValue,[name]:value});
  }

  return (
    <div className="min-h-screen flex flex-col">
    <Header />

    {/* Main Content */}
    <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900  via-green-500 to-green-200">
      <div className="bg-white p-8 shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-800">Sign up</h2>
        <form className="mt-4 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">Company</label>
            <input id="company" name="company" type="text" required onChange={onInputChange} value={company}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="person" className="block text-sm font-medium text-gray-700">Full name</label>
            <input id="person" name="person" type="text" required onChange={onInputChange} value={person}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input id="email" name="email" type="email" required onChange={onInputChange} value={email}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
            <input id="password" name="password" type="password" required onChange={onInputChange} value={password}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="confirm" className="block text-sm font-medium text-gray-700">Confirm password</label>
            <input id="confirm" name="confirm" type="password" required onChange={onInputChange} value={confirm}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">Country</label>
            <input id="country" name="country" type="text" required onChange={onInputChange} value={country}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">City</label>
            <input id="city" name="city" type="text" required onChange={onInputChange} value={city}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
            <input id="address" name="address" type="text" required onChange={onInputChange} value={address}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="remember_me" className="m-2 block text-sm text-gray-900">
              You are registered? <Link to="/login" className="text-green-700 underline">Sign in here.</Link>
            </label>
          </div>

          <div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
              Sign up
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default Register