import React, {useState, useMemo} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, selectUser } from '../redux/features/authSlice';

const Header = React.memo(() => {
    const [show,setShow] = useState(false);
    const user = useSelector(selectUser);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = () =>{
      dispatch(logoutUser());
      navigate('/');
    }
  return (
     <header className="w-full fixed top-0 left-0 bg-transparent backdrop-filter backdrop-blur-lg bg-opacity-100 z-50 shadow-md">
     <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
     <div className="flex flex-col">
     <FontAwesomeIcon icon={faBars} onClick={()=>setShow(!show)} className="text-white text-3xl" />
     </div>
     {show && !user?.result?._id &&
  <div className="flex flex-col text-white">

 <Link to="/login"><button className=''>Login</button></Link>
 <Link to="/register"><button className=''>Register</button></Link>
 <Link to="/"><button className=''>How app works</button></Link>
  </div>}
  {show && user?.result?._id &&
  <div className="flex flex-col text-white">
 <Link to="/"><button className=''>How app works</button></Link>
 <button onClick={handleLogout} className=''>Logout</button>
  </div>}
  {!show && <Link to="/">
    <i className="text-2xl font-bold text-white border border-white py-1 pl-1">Planner<b className='text-green-500 bg-white p-1 ml-1'>Data</b></i> 

     </Link>}
       <div>
         <Link to="/login">
         <button className="text-white border border-white px-4 py-2 rounded-md">
         Login
         </button></Link>

       </div>
     </div>
   </header>
  )
})

export default Header