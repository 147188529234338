import React from 'react';

const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="loader">
        <div className="border-t-transparent border-solid animate-spin rounded-full border-4 border-green-500 border-t-transparent h-16 w-16"></div>
        <i className='bg-gradient-to-r from-green-400 to-green-200 font-bold bg-clip-text text-transparent mt-4'>Loading..</i>
    
      </div>
    </div>
  );
};

export default Spinner;
