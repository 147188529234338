import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createSession } from '../redux/features/sessionSlice';
import TimeInput from '../components/DatePicker';
import { createClient } from '../redux/features/clientSlice';

const initialState = {
  firstName:'',
  lastName:'',
  phone:'',
  email:'',
  date:'',
  time:'',
  price:'',
  workerFirstName:'',
  workerLastName:'',
  service:''
}

const AddSession = ({workers, services, goToSessions})=> {
      const user = useSelector((state) => state.auth.user);
      const clients = useSelector((state) => state.client.clients);
      const [sessionData, setSessionData] = useState(initialState);
      const [countryCode, setCountryCode] = useState('');
      const [isOpenWorker, setIsOpenWorker] = useState(false);
      const [isOpenService, setIsOpenService] = useState(false);
      const [selectedWorker, setSelectedWorker] = useState(null); 
      const [selectedService, setSelectedService] = useState(null); 
      const {  phone , price} = sessionData;
      const [phoneNumber, setPhoneNumber] = useState(''); 
      const [suggestedPhone, setSuggestedPhone] = useState('');
      const dispatch = useDispatch();
      const navigate = useNavigate();
      useEffect(() => {
        if (phoneNumber) {
          // Filtriraj arhive na osnovu broja telefona
          const match = clients.find((client) => client.phone.startsWith(phoneNumber));
          if (match) {
            setSuggestedPhone(match.phone); // Postavi predlog ako postoji podudaranje
          } else {
            setSuggestedPhone(''); // Očisti predlog ako nema podudaranja
          }
        } else {
          setSuggestedPhone('');
        }
      }, [phoneNumber, clients]);

     const toggleDropdownWorker = () => {
        setIsOpenWorker(!isOpenWorker);
      };
      const handleSelectWorker = (worker) => {
        
        setSelectedWorker(worker); 
        setSessionData((prevState) => ({
          ...prevState,
          workerFirstName: worker.firstName,
          workerLastName: worker.lastName,
      }));
        setIsOpenWorker(false); 
      };
      const toggleDropdownService = () => {
        setIsOpenService(!isOpenService);
      };
      const handleSelectService = (service) => {
        setSelectedService(service); 
          setSessionData((prevState) => ({
            ...prevState,
            service: service.service,
            price: service.price
        }));
        setIsOpenService(false); 
      };
      
    const getUserCountryCode = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          setCountryCode(data.country_code.toLowerCase()); 
        } catch (error) {
        }
      };
      useEffect(() => {
        getUserCountryCode();
      }, []);
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setSessionData({...sessionData, [name]: value})
      };
      const handleTimeChange = (selectedTime) => {
        setSessionData((prevState) => ({
          ...prevState,
          time: selectedTime 
        }));
      };
      const handleSubmit = async (e) =>{
        e.preventDefault();
          const updatedSessionData = {...sessionData, name:user?.result?.email};
          dispatch(createSession({updatedSessionData, toast}));
          const existingClient = clients.find(client => client.phone === sessionData.phone);
          if (!existingClient) {
            const updatedClientData = {
                firstName: sessionData.firstName, 
                lastName: sessionData.lastName,
                phone: sessionData.phone,
                email: sessionData.email  
            };
            // Dispatch za kreiranje novog klijenta
            await dispatch(createClient({ updatedClientData, toast, navigate }));
          navigate('/admin');
          goToSessions();
          handleClear();
      }else{
        toast.success("Client exist in data base")
      }
    };
   
    const handleClear = () =>{
      setSessionData({ firstName:'', lastName:'',email:'', phone:'', service:'', data:'', time:'', worker:''})
    };
  return (
<div>
       <h2 className="text-xl font-bold text-white mb-1">Add Session</h2>
        <form className="space-y-6 w-full " onSubmit={handleSubmit} noValidate>
            <div className="flex md:flex-row flex-col w-full">
            <div className='md:w-1/3 w-full'>
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} id="firstName" name="firstName" type="text" required className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/3 w-full'>
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} id="lastName" name="lastName" type="text" required className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="client" className="block text-xs font-medium text-gray-200">Phone Number</label>
            <PhoneInput
        country={countryCode} // Setuje automatski prefiks na osnovu države
        enableSearch={true} // Omogućava pretragu država
        value={phone} // Povezivanje vrednosti sa stanjem
        onChange={(value) => setSessionData({ ...sessionData, phone: `+${value }`})} // Ažuriranje broja telefona
        inputStyle={{
            width: '100%',
            padding: '12px',
            borderRadius: '7px',
            border: '1px solid #ccc',
            paddingLeft:'50px',
            height:'32px'
          }}
      /> {suggestedPhone && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            left: '10px',
            color: '#888',
            backgroundColor: '#f0f0f0',
            padding: '5px 10px',
            borderRadius: '4px',
            zIndex: 1,
          }}
        >
          Suggested: {suggestedPhone}
        </div>
      )}
      </div>
            </div>
            <div className="flex md:flex-row flex-col w-full">
           
          <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="email" className="block text-xs font-medium text-gray-100">Email</label>
            <input onChange={onInputChange} id="email" name="email" type="text" required className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
<div className='relative md:w-1/3 w-full mt-1'>
      <label htmlFor="service" className="block text-xs font-medium text-gray-100">Treatment</label>
      <div 
        className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownService}
      >
        {/* Prikaži izabranog radnika ili default poruku */}
        {selectedService ? `${selectedService.service}` : 'Select a treatment'}
      </div>
      
      {isOpenService && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {services.length > 0 ? (services.map((service) => (<li key={service._id} 
      className="flex justify-between cursor-pointer hover:bg-green-500 bg-green-100  text-black p-2"
      onClick={() => handleSelectService(service)}>
    {service.service} 
    </li>
  ))
) : (
  <p className="text-green-500 p-2">No services available.</p>
)}
<hr />
        </ul>
      )}
    </div>
<div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="price" className="block text-xs font-medium text-gray-100">Price</label>
            <input id="price" name="price" type="text" value={price} onChange={onInputChange} className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex md:flex-row flex-col w-full">  
<div className='relative md:w-1/3 w-full mt-1'>
      <label htmlFor="worker" className="block text-xs font-medium text-gray-100">Worker</label>
      <div 
        className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownWorker}
      >
        {/* Prikaži izabranog radnika ili default poruku */}
        {selectedWorker ? `${selectedWorker.firstName} ${selectedWorker.lastName}` : 'Select a worker'}
      </div>
      
      {isOpenWorker && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {workers.length > 0 ?  (workers.map((worker) => (
            <li 
              key={worker._id} 
              className="flex justify-between  cursor-pointer hover:bg-green-500 bg-green-100 text-black p-2"
              onClick={() => handleSelectWorker(worker)}
            >
            {worker.firstName} {worker.lastName}
            </li>
          ))):( <p className="text-green-500 p-2">No services available.</p>)}<hr />
        </ul>
      )}
    </div> 
           <div className='md:w-1/3 w-full mt-1'>
             <label htmlFor="date" className="block text-xs font-medium text-gray-100">Date</label>
             <input onChange={onInputChange} id="date" name="date" type="date" required className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='md:w-1/3 w-full mt-1'>
             <label htmlFor="time" className="block text-xs font-medium text-gray-100">Time</label>
             <TimeInput onTimeChange={handleTimeChange} />
 </div>
 </div>         
          <button type="submit" className="w-full flex justify-center py-2 px-2 bor1er border-transparent rounded-md shadow-xs text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div>
  )
}

export default AddSession;