import { configureStore } from "@reduxjs/toolkit";

import authReducer from './features/authSlice.js';
import clientReducer from './features/clientSlice.js';
import workerReducer from './features/workerSlice.js';
import serviceReducer from './features/serviceSlice.js';
import sessionReducer from './features/sessionSlice.js';
import archiveReducer from './features/archiveSlice.js';
import categoryReducer from './features/categorySlice.js';
import smsReducer from './features/smsSlice.js';

export default configureStore({
    reducer:{
        auth:authReducer,
        client:clientReducer,
        worker:workerReducer,
        service:serviceReducer,
        session:sessionReducer,
        archive:archiveReducer,
        category:categoryReducer,
        sms:smsReducer
    }
})