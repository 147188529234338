import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoadingToRedirect = () => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount);
    }, 1000);

    count === 0 && navigate("/login");
    return () => clearInterval(interval);
  }, [count, navigate]);
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200">

    <div className="flex justify-center items-center h-screen">
      <h5 className="bg-gradient-to-r from-green-200 to-green-100 font-bold bg-clip-text text-transparent mt-4">
        Redirecting you to login <br/> page  in {count} seconds</h5>
    </div></div>
  );
};

export default LoadingToRedirect;