import React, { useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TimeInput = React.memo(({onTimeChange}) => {
    const defaultTime =  new Date()
    
  defaultTime.setHours(0, 0, 0, 0); 
    const [selectedTime, setSelectedTime] = useState(defaultTime)
const handleChange = (time) =>{
    setSelectedTime(time)
    const hours = time.getHours().toString().padStart(2, '0'); // Dodaj nulu ako je jednocifren broj
    const minutes = time.getMinutes().toString().padStart(2, '0'); 

    // Kreiraj promenljivu u formatu "HH:mm"
    const formattedTime = `${hours}:${minutes}`;

    // Prosledi parent komponenti
    onTimeChange(formattedTime);
}
  return (
    <DatePicker
      selected={selectedTime}
      onChange={handleChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60}
      timeCaption="Time"
      dateFormat="HH:mm"  
      className="mt-1  px-4 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
    />
  );
});

export default TimeInput;