import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import ColorDropdown from '../components/ColorDropdown';
import { updateCategory } from '../redux/features/categorySlice';
import HeaderAdmin from '../components/HeaderAdmin';

const initialState = {
 category:'',
 colorFinish:''
}

const EditCategory = ({goToClients})=> {
    const [categoryData, setCategoryData] = useState(initialState);

    const error = useSelector((state) => state.client.error);
    const categories = useSelector((state) => state.category.categories);

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
useEffect(()=>{
    const singleCategory = categories.find((client)=> client._id === id);
    setCategoryData({...singleCategory})
},[])

    const {category,colorFinish} = categoryData;

    useEffect(()=>{
    error && toast.error(error)
    },[error])
 
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setCategoryData({...categoryData, [name]: value})
      };
      const handleSubmit = (e) =>{
        console.log(categoryData);
        
          e.preventDefault();
            const updatedCategoryData = {...categoryData, name:user?.result?.name}
       dispatch(updateCategory({id, updatedCategoryData, navigate, toast}))  
            handleClear();
          }
      const handleClear = () =>{
        setCategoryData({ category:'', colorFinish:''})
      };
      const takeColor = (color)=>{
        setCategoryData({...categoryData, colorFinish:color});
       }
  return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
      {/* Header */}
      <HeaderAdmin />
      <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">Edit Category</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <div className="flex flex-col w-full">
            <div className='md:w-1/2 w-full '>
            <label htmlFor="category" className="block text-sm font-medium text-gray-100">Category</label>
            <input onChange={onInputChange} value={categoryData.category} id="category"  name="category" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <label htmlFor="color" className="block text-sm font-medium text-gray-100">Color of Category</label>

         <ColorDropdown oldColor={categoryData.colorFinish} forwardColor={takeColor}/>
          
            </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div></div>
  )
}

export default EditCategory;