import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';

export const createSMS = createAsyncThunk(
    "sms/createSMS",
    async({SMSmessage, toast}, {rejectWithValue}) =>{

        try {
        const response = await api.createSMS({SMSmessage});
            toast.success("SMS create successfully");
        return response.data;
            } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)
export const getSMS = createAsyncThunk(
    "sms/getSMS", async(_,{rejectWithValue}) =>{
    try{
const response = await api.getSMS();
console.log(response.data);

        return response.data;
    }catch(err){
return rejectWithValue(err.response.data)    }
});

export const sendSMSMessage = createAsyncThunk(
    "sms/sendSMS",
    async({message, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.sendSMSMessage({message});
            toast.success("SMS sent successfully");
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data)
        }
    }
)
const smsSlice = createSlice({
    name:'sms',
    initialState:{
        error:'',
        loading:false,
        sms:[]
    },
    
    extraReducers: (builder) => {
        builder
            .addCase(createSMS.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSMS.fulfilled, (state, action) => {
                state.loading = false;
                state.sms = [...state.sms, action.payload];
            })
            .addCase(createSMS.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
           .addCase(getSMS.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSMS.fulfilled, (state, action) => {
                state.loading = false;
                state.sms =  action.payload;
                console.log(state.sms);
                
            })
            .addCase(getSMS.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })}
           
})
export default smsSlice.reducer;