import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { faPhone, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
const ClientData = ({sessionsHistory, closePopup}) => {
  const [showGalery, setShowGalery] = useState(false);
  const user = useSelector(selectUser);
  
  const formattedDate = new Date(sessionsHistory.createdAt).toLocaleDateString('sr-RS', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div 
      className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
      onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
    >
      <h2 className="text-xl font-bold mb-4">All Session Data</h2>
      <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
      <ul className="border-b border-transparent last:border-none">
      <li className="px-2 py-1 text-black">{sessionsHistory.date} {sessionsHistory.time}</li>
      <li className="px-2 py-1 text-black">{sessionsHistory.firstName} {sessionsHistory.lastName}</li>
      <hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionsHistory.phone}</li>
      <a href={`tel:${sessionsHistory.phone}`} ><FontAwesomeIcon className='p-2' icon={faPhone} /></a></div><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionsHistory.email}</li>
      <a href={`mailto:${sessionsHistory.email}`} ><FontAwesomeIcon className='p-2' icon={faEnvelope} /></a></div><hr/>
      <li className="px-2 py-1 text-black">{sessionsHistory.service}</li><hr/>
      <li className="px-2 py-1 text-black">{sessionsHistory.price}</li><hr/>
      <li className="px-2 py-1 text-black">{sessionsHistory.workerFirstName}{sessionsHistory.workerLastName}</li><hr/>
      <li className="px-2 py-1 text-black">
        <button className='border border-black px-2 rounded-md' onClick={()=>setShowGalery(!showGalery)}>Open or close galery images</button>
        {showGalery &&
        <div className="flex flex-col ">
          <div className="flex flex-row justify-center">
          <img src={sessionsHistory.image1} className='p-1 w-[100px] h-[120px]'  alt="No image" />
          <img src={sessionsHistory.image2} className='p-1 w-[100px] h-[120px]' alt="No image" /></div>
          <div className="flex flex-row justify-center">
          <img src={sessionsHistory.image3} className='p-1 w-[100px] h-[120px]'  alt="No image" />
          <img src={sessionsHistory.image4} className='p-1 w-[100px] h-[120px]' alt="No image" />
         </div>
         </div>}
    
      </li>
     <hr/>
      <li className="px-2 py-1 text-black"><i>Archived <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
      </ul>
      </div>
      <button 
        onClick={closePopup} 
        className="mt-4 px-4 py-2 justify-end w-full bg-green-900 text-white rounded-sm"
      >
        Client History (under development)
      </button>
      <div className='flex justify-end'>
      <button 
        onClick={closePopup} 
        className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
      >
        <FontAwesomeIcon className='p-2' icon={faTimes} />
      </button>
      </div>
   
     
    </div>
  </div>
  )
}

export default ClientData