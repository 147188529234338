import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from '../api.js';


export const createSession = createAsyncThunk(
    "session/createSession",
    async ({updatedSessionData, toast}, {rejectWithValue}) =>{
        
        try {
            const response = await api.createSession(updatedSessionData);
            toast.success('Session added successfuly');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const getUserSessions = createAsyncThunk(
    "session/getUserSessions",
    async ({userId},{rejectWithValue})=>{
        try{
            const response = await api.getUserSessions(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const deleteSession = createAsyncThunk(
    "session/deleteSession",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteSession(id);
            toast.success('Item deleted from sessions list')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateSession = createAsyncThunk(
    "client/updateSession",
    async ({id, updatedSessionData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateSession(id, updatedSessionData);
            toast.success('Session updated successfuly');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
{/*
export const getClients = createAsyncThunk(
    "clsessionetClient",
    async ({userId},{rejectWithValue})=>{
        try{
            
            const response = await api.getClients(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

*/}
const sessionSlice = createSlice({
    name:'session',
    initialState:{
        session:{},
        sessions:[],
        userSession:[],
        error:"",
        loading:false
    },
    reducers: {
        resetError(state) {
          state.error = null;
        },
      },
    extraReducers: (builder) => {
        builder
            .addCase(createSession.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSession.fulfilled, (state, action) => {
                state.loading = false;
                state.sessions = [...state.sessions, action.payload];
            })
            .addCase(createSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
           .addCase(getUserSessions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserSessions.fulfilled, (state, action) => {
                state.loading = false;
                state.sessions =  action.payload;
                
            })
            .addCase(getUserSessions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
           
            .addCase(deleteSession.pending, (state) => {
                state.loading = true;
            })  
            .addCase(deleteSession.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.sessions = state.sessions.filter((item)=> item._id !== id)
                }
            })
            .addCase(deleteSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
            .addCase(updateSession.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSession.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.sessions = state.sessions.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
    }
});
export default sessionSlice.reducer;