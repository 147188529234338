import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as api from '../api.js';

export const createArchive = createAsyncThunk(
    "archive/createArchive",
    async ({archiveData, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createArchive(archiveData);
            toast.success('Session archived successfuly');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const getUserArchives = createAsyncThunk(
    "archive/getUserArchives",
    async ({userId},{rejectWithValue})=>{
        try{
            const response = await api.getUserArchives(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteArchive = createAsyncThunk(
    "archive/deleteArchive",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteArchive(id);
            toast.success('Archive deleted successfuly')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
{/*

export const updateSession = createAsyncThunk(
    "client/updateSession",
    async ({id, updatedSessionData, navigate, toast},{rejectWithValue})=>{
        try{
            
            const response = await api.updateSession(id, updatedSessionData);
            
            toast.success('Session updated successfuly');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

export const getClients = createAsyncThunk(
    "clsessionetClient",
    async ({userId},{rejectWithValue})=>{
        try{
            
            const response = await api.getClients(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

*/}
const archiveSlice = createSlice({
    name:'archive',
    initialState:{
        archive:{},
        archives:[],
        userArchives:[],
        error:"",
        loading:false
    },
    reducers: {
        resetError(state) {
          state.error = null;
        },
      },
    extraReducers: (builder) => {
        builder
            .addCase(createArchive.pending, (state) => {
                state.loading = true;
            })
            .addCase(createArchive.fulfilled, (state, action) => {
                state.loading = false;
                state.archives = [...state.archives, action.payload];
            })
            
            .addCase(createArchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
          .addCase(getUserArchives.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserArchives.fulfilled, (state, action) => {
                state.loading = false;
                state.archives =  action.payload;
                
            })
            .addCase(getUserArchives.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(deleteArchive.pending, (state) => {
                state.loading = true;
            })  
            .addCase(deleteArchive.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.archives = state.archives.filter((item)=> item._id !== id)
                }
            })
            .addCase(deleteArchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
           {/* 

            .addCase(updateSession.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSession.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.sessions = state.sessions.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })*/}
    }
});
export default archiveSlice.reducer;