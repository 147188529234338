import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEdit, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { deleteClient } from '../redux/features/clientSlice';
import ClientData from './ClientData';
import Pagination from '../components/Pagination';
const Clients = ({ data}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showData, setShowData] = useState(false);
  const [clientData, setClientData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;
  const totalPages = Math.ceil(data.length / clientsPerPage);
  const dispatch = useDispatch();
  const handleDelete = (id) =>{
if(window.confirm('Are you sure you want to delete this client?',id)){
    dispatch(deleteClient({id, toast}))
}
  }
  const openShowData = (id)=>{
    const selectedClient = data.find(client => client._id === id);
    setClientData(selectedClient); 
    setShowData(true); 
  }

  const filteredData = data.filter(client =>
    client.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    client.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);
  return (
    <div className="">
      <div className="flex flex-row justify-between">
      <h2 className="text-xl font-bold text-white mb-1">Clients</h2>
      <div className="relative ml-auto">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-1 pl-8 rounded-lg bg-white text-xs text-gray-800 border border-gray-300 w-[150px]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-transparent rounded-lg shadow-lg">
          <thead>
            <tr className="bg-transparent text-white">
              <th className="px-1 py-1 text-left">#</th>
              <th className="px-1 py-1 text-left">Name</th>
              <th className="px-1 py-1 text-left">Phone</th>
              <th className="px-1 py-1 text-left">Action</th>

            </tr>
          </thead>
          <tbody>
          {searchTerm !== '' && filteredData.length > 0 ? (
              filteredData
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b border-transparent last:border-none text-sm border border-black bg-green-900">
                      <td className="px-1 py-1 text-white text-sm">{index + 1}</td>
                      <td className="px-1 py-1 text-white text-sm">{item.firstName} {item.lastName}</td>
                      <td className="px-1 py-1 text-white text-sm">{item.phone}</td>
                      <td className="px-1 py-1">
                        <div className="flex flex-row space-x-4">
                          <button className="text-sm hover:text-base hover:text-gray-900">
                            <Link to={`/editClient/${item._id}`}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                          </button>
                          <button className="text-sm hover:text-base hover:text-gray-900" onClick={() => handleDelete(item._id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button className="text-sm hover:text-base hover:text-gray-900" onClick={() => openShowData(item._id)}>
                            <i>View..</i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                     
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                </td>
              </tr>
            )}
            {searchTerm === '' && data.length > 0 ? (
              data
              .slice() // Kreiramo kopiju da ne mutiramo originalni niz
              .sort((a, b) => a.lastName.localeCompare(b.lastName)) // Sortiranje po lastName
              .map((item, index) => (
                <React.Fragment key={index}>
                  <tr className="border-b border-transparent last:border-none text-sm">
                  <td className="px-1 py-1 text-white text-sm">{index + 1}</td>
                    <td className="px-1 py-1 text-white text-sm">{item.firstName} {item.lastName}</td>
                    <td className="px-1 py-1 text-white text-sm">{item.phone}</td>
                    <td className="px-1 py-1">
                    <div className="flex flex-row space-x-4">
                      <button className="text-sm hover:text-base hover:text-gray-900">
                        <Link to={`/editClient/${item._id}`}>
                        <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </button>
                      <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>handleDelete(item._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                       <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>openShowData(item._id)}>
                        <i >View..</i> 
                      </button>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <hr className="border-t border-white my-1" />
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                  No clients from base.
                </td>
              </tr>
            )}
               {/*<Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />*/}
          </tbody>
        </table>
      </div>
      {showData && (
<ClientData clientData={clientData}   closePopup={() => setShowData(false)}/>
)}

    </div>
  );
};

export default Clients;
