import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEdit, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SessionData from './SessionData';
import { deleteSession } from '../redux/features/sessionSlice';
const Sessions = ({ sessions}) => {
  const formattedDate = new Date(sessions.date).toLocaleDateString('sr-RS', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [showData, setShowData] = useState(false);
  const [sessionData, setSessionData] = useState();
  const dispatch = useDispatch();
  const handleDelete = (id) =>{
    if(window.confirm('Are you sure you want to delete this session?',id)){
        dispatch(deleteSession({id, toast}))
    }
      }
  const openShowData = (id)=>{
    const selectedSession = sessions.find(session => session._id === id);
    setSessionData(selectedSession); 
    setShowData(true); 
  }
  const filteredData = sessions.filter(session =>
    session.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    session.lastName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="">
      <div className="flex flex-row">
      <h2 className="text-xl font-bold text-white mb-1">Sessions</h2>
      <div className="relative ml-auto">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-1 pl-8 rounded-lg bg-white text-xs text-gray-800 border border-gray-300 w-[150px]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="min-w-full bg-transparent rounded-lg shadow-lg">
          <thead>
            <tr className="bg-transparent text-white">
              <th className="px-2 py-1 text-left">#</th>
              <th className="px-2 py-1 text-left">Time</th>
              <th className="px-2 py-1 text-left">Full name</th>
              <th className="px-2 py-1 text-left">Action</th>

            </tr>
          </thead>
          <tbody>
          {searchTerm !== '' && filteredData.length > 0 ? (
              filteredData
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b border-transparent last:border-none text-sm border border-black bg-green-900">
                      <td className="px-1 py-1 text-white text-sm">{index + 1}</td>
                      <td className="px-1 py-1 text-white text-sm">{item.firstName} {item.lastName}</td>
                      <td className="px-1 py-1 text-white text-sm">{item.phone}</td>
                      <td className="px-1 py-1">
                        <div className="flex flex-row space-x-4">
                          <button className="text-sm hover:text-base hover:text-gray-900">
                            <Link to={`/editClient/${item._id}`}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                          </button>
                          <button className="text-sm hover:text-base hover:text-gray-900" onClick={() => handleDelete(item._id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button className="text-sm hover:text-base hover:text-gray-900" onClick={() => openShowData(item._id)}>
                            <i>View..</i>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                     
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                </td>
              </tr>
            )}
            {searchTerm === '' && sessions.length > 0 ? (
              sessions
              .slice() // Kreiramo kopiju da ne mutiramo originalni niz
              .sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
          
                // Ako su datumi različiti, sortiraćemo po datumu
                if (dateA - dateB !== 0) {
                  return dateA - dateB;
                }
          
                // Ako su datumi isti, sortiramo po vremenu
                const [hourA, minuteA] = a.time.split(':').map(Number);
                const [hourB, minuteB] = b.time.split(':').map(Number);
          
                return hourA !== hourB ? hourA - hourB : minuteA - minuteB;
              })
              .map((item, index) => (
                <React.Fragment key={index}>
                  <tr className="border-b border-transparent last:border-none">
                  <td className="px-2 text-sm text-white">{index + 1}</td>
                  <td className="px-2 text-sm text-white">{`${ new Date(item.date).toLocaleDateString('sr-RS', {
                      day: '2-digit',
                      month: 'short',
                    })}.`
                   }<br/>{item.time}</td>
                    <td className="px-2 text-sm text-white">{item.firstName} {item.lastName}</td>
                    <td className="px-2 text-sm">
                    <div className="flex flex-row space-x-4">
                      <button className="text-sm hover:text-base hover:text-gray-900">
                        <Link to={`/editSession/${item._id}`}>
                        <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </button>
                      <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>handleDelete(item._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                       <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>openShowData(item._id)}>
                        <i >View..</i> 
                      </button>
                    </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      <hr className="border-t border-white my-1" />
                    </td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-4 py-2 text-white">
                  No sessions from base.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showData && (
<SessionData sessionData={sessionData} closePopup={() => setShowData(false)}/>
)}
    </div>
  );
};

export default Sessions;
