import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import ServiceData from './ServiceData';
import { deleteOneService } from '../redux/features/serviceSlice';
import {toast} from 'react-toastify';

const Services = ({services}) => {
  const dispatch  = useDispatch();
  
    const [showData, setShowData] = useState(false);
    const [serviceData, setServiceData] = useState()
    const openShowData = (id)=>{
        const selectedService = services.find(service => service._id === id);
        setServiceData(selectedService); 
        setShowData(true); 
      }
      
      const handleDelete = (id) =>{
        if(window.confirm('Are you sure you want to delete this service?',id)){
            dispatch(deleteOneService({id, toast}))
        }
          }
  return (
    <div>
    <ul className="flex flex-col md:w-1/2">
    {services.map((item, index) => (
      <li
        key={item._id}
        className="flex flex-row justify-between font-bold border border-black"
        style={{ backgroundColor: item.colorFinish, padding: '2px' }}
      >
        {index + 1}. {item.service}
        <div className="flex flex-row">
          <button className="text-sm hover:text-base hover:text-gray-900 mx-1">
            <Link to={`/editService/${item._id}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </button>
          <button
            className="text-sm hover:text-base hover:text-gray-900 mx-1"
            onClick={() => handleDelete(item._id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
          <button
            className="text-sm hover:text-base hover:text-gray-900"
            onClick={() => openShowData(item._id)}
          >
            <i>View..</i>
          </button>
        </div>
      </li>
    ))}
  </ul>
  {showData && (
    <ServiceData serviceData={serviceData} closePopup={() => setShowData(false)}/>
    )}</div>
  )
}

export default Services