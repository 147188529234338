import React, { useState } from 'react';

const PriceInput = ({ sendPrice, oldPrice }) => {
  const [price, setPrice] = useState('');
  const formatPrice = (value) => {
    const numericValue = value.replace(/[^0-9,]/g, '');
    const parts = numericValue.split(',');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); 
    if (parts[1] === undefined) {
      return `${integerPart},00`; 
    } else {
      return `${integerPart},${parts[1].slice(0, 2)}`; 
    }
  };

  const onInputChange = (e) => {
    const formattedPrice = formatPrice(e.target.value);
    setPrice(formattedPrice);
    
    const numericPrice = formattedPrice.replace(/\./g, '').replace(',', '.');
    sendPrice(parseFloat(numericPrice) || 0);
  };
  console.log(oldPrice);
  const formattedOldPrice = oldPrice 
  ? formatPrice(oldPrice.toString().replace('.', ',')) 
  : "0,00";
  if(!oldPrice){
    oldPrice = '0,00';
  } 
  return (
    <div>
      <input
        value={price}
        onChange={onInputChange}
        id="price"
        name="price"
        type="text"
        inputMode="decimal"
        placeholder={formattedOldPrice}
        className="w-full px-2 py-1 text-lg border border-gray-300 rounded-lg shadow-md focus:ring-4 focus:ring-green-500 focus:border-green-500 focus:outline-none transition-all duration-300 ease-in-out placeholder-gray-400"
      />
    </div>
  );
};

export default PriceInput;

