import React, {useState, useEffect, useMemo} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { login, selectLoading, selectError, selectUser, clearError } from '../redux/features/authSlice';
import Header from '../components/Header';
const initialState = {
email:'',
password:''
}

const Login = () => {
   const [formValue, setFormValue] = useState(initialState);
   const {email, password} = formValue;
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const loading = useSelector(selectLoading);
   const error = useSelector(selectError);
   
useEffect(()=>{
  error&&toast.error(error)
  dispatch(clearError()); 
},[error])

   const handleSubmit = (e) =>{
  e.preventDefault();
  if( email && password ){
    dispatch(login({formValue,navigate, toast}));
  }
   }
   const onInputChange = (e) =>{
  let {name, value} = e.target;
  setFormValue({...formValue,[name]:value});
   }
  return (
    <div className="min-h-screen flex flex-col">
  {/* Header */}
<Header />

  {/* Main Content */}
  <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900 via-green-500 to-green-200">
    <div className="bg-white p-8 shadow-lg w-full max-w-md">
      <h2 className="text-2xl font-bold text-center text-gray-800">Login</h2>
      <form className="mt-4 space-y-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            required
            onChange={onInputChange}
            value={email}
            className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
          />
        </div>

        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            required
            onChange={onInputChange}
            value={password}
            className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
              You are not registered?
              <Link to="/register" className="text-green-700 underline ml-1">
                Sign up here.
              </Link>
            </label>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
          >
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

  )
}

export default Login