import React from 'react'
import NotFound from '../images/404-error.webp';
import { Link } from 'react-router-dom';
const PageNotFound = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
        <img src={NotFound} className='w-[350px]' />
        <button className='w-[300px] mt-4 p-2 m-2  bg-green-900 text-white rounded-md'>
        <Link to='/admin'>Back to home</Link>
        </button>
    </div>
  )
}

export default PageNotFound