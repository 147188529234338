import React from "react";
import { useSelector } from "react-redux";
import LoadingToRedirect from "./LoadingToRedirect";
import { selectUser } from "../redux/features/authSlice";

const PrivateRoute = ({ children }) => {
    const user = useSelector(selectUser);
  return user ? children : <LoadingToRedirect />;
};

export default PrivateRoute;