import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import * as api from '../api.js'
const selectAuthState = (state) => state.auth;

export const selectLoading = createSelector(
    [selectAuthState],
    (auth) => auth.loading,    
  );
  
  // Memoizovani selektor za error
  export const selectError = createSelector(
    [selectAuthState],
    (auth) => auth.error
  );
  export const selectUser = createSelector(
    [selectAuthState],
    (auth) => {
      // If the user is already in the auth state, return it
      if (auth.user) {
        return auth.user;
      }
      
      // Otherwise, check localStorage
      const storedUser = localStorage.getItem("Profile");
      
      if (storedUser) {
        return JSON.parse(storedUser);  // Parse and return the user from localStorage
      }
      
      return null; // Return null if there's no user in state or localStorage
    }
  );
 
export const login = createAsyncThunk("auth/login", async({formValue, navigate, toast},{rejectWithValue})=>{
    try {
        const response = await api.signin(formValue);
        toast.success("Login Successfuly");
        navigate("/admin");
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})
export const register = createAsyncThunk("auth/register", async({formValue, navigate, toast},{rejectWithValue})=>{
    try {
        const response = await api.signup(formValue);
        toast.success("Register Successfuly");
        navigate("/admin");
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

const authSlice = createSlice({
    name:'auth',
    initialState:{
        user:null,
        error:"",
        loading:false
    },
    reducers: {
      setUser: (state, action) =>{
        state.user = action.payload;
   },
        clearError: (state) => {
          state.error = null; // Clear the error
        },
        logoutUser: (state) => {
            localStorage.clear();
            state.user = null;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                localStorage.setItem("Profile", JSON.stringify({ ...action.payload }));
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(register.pending, (state) => {
                state.loading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = false;
                localStorage.setItem("Profile", JSON.stringify({ ...action.payload }));
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
               state.error = action.payload.message ;
               state.error = null;
            })
    }
});
export const { clearError, logoutUser, setUser } = authSlice.actions;
export default authSlice.reducer;