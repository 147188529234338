import React , {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import FileBase from "react-file-base64";

import { faPhone, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import AddArchive from '../forms/AddArchive';
import { createArchive } from '../redux/features/archiveSlice';
import { deleteSession } from '../redux/features/sessionSlice';
const SessionData = ({sessionData, closePopup}) => {
  const [archiveData, setArchiveData] = useState(sessionData)
  const user = useSelector(selectUser);
  const [sessionView, setSessionView] = useState(false);
  const dispatch = useDispatch();
  const formattedDate = new Date(sessionData.createdAt).toLocaleDateString('sr-RS', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  const sessionDate = new Date(sessionData.date).toLocaleDateString('sr-RS', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  
  });
  const saveSession = async() =>{
    const sessionId = sessionData._id;
    try {
      // Prvo kreiraj arhivu
      const createResult = await dispatch(createArchive({ archiveData, toast }));
  
      if (createResult.meta.requestStatus === "fulfilled") {
        // Ako je kreiranje arhive uspelo, pokreni brisanje sesije
        const deleteResult = await dispatch(deleteSession({ id: sessionId, toast }));
  
        if (deleteResult.meta.requestStatus === "fulfilled") {
        } else {
          toast.error("Failed to delete the session!");
        }
      } else {
        toast.error("Failed to archive the session!");
      }
    } catch (error) {
      toast.error("An error occurred!");
    }
    closePopup();
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div 
      className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
      onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
    >
     <div> <h2 className="text-xl font-bold mb-4">All Session Data</h2>
      <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
      <ul className="border-b border-transparent last:border-none">
      <li className="px-2 py-1 text-black">{sessionDate} | {sessionData.time}</li><hr/>
      <li className="px-2 py-1 text-black">{sessionData.firstName} {sessionData.lastName}</li><hr/>
      <li className="px-2 py-1 text-black">{sessionData.service}</li><hr/>
      <li className="px-2 py-1 text-black">{sessionData.price}</li><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionData.phone}</li>
      <a href={`tel:${sessionData.phone}`} ><FontAwesomeIcon className='p-2' icon={faPhone} /></a></div><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{sessionData.email}</li>
      <a href={`mailto:${sessionData.email}`} ><FontAwesomeIcon className='p-2' icon={faEnvelope} /></a></div><hr/>
      <li className="px-2 py-1 text-black">Worker: {sessionData.workerFirstName} {sessionData.workerLastName}</li><hr/>
      <li className="px-2 py-1 text-black"><i>Created <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
      </ul>
      <div className="flex flex-col m-1 w-full p-2">
      <h6>Images from session (max 4): </h6>
            <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image1: base64 })
                }
              />
               <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image2: base64 })
                }
              />
        <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image3: base64 })
                }
              />
                <FileBase
                type="file"
                multiple={false}
                onDone={({ base64 }) =>
                  setArchiveData({ ...archiveData, image4: base64 })
                }
              />
       
    </div>
      </div>
      <button 
        onClick={saveSession} 
        className="mt-4 px-4 py-2 justify-end w-full bg-green-900 text-white rounded-sm"
      >
        Complete Session 
      </button></div>
      <div className='flex justify-end'>
      <button 
        onClick={closePopup} 
        className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
      >
        <FontAwesomeIcon className='p-2' icon={faTimes} />
      </button>

      </div>
    </div>
   
  </div>
  )
}

export default SessionData