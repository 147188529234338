import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

const colors = [
  'red', 'blue', 'green', 'yellow', 'purple', 'orange', 'pink',
  'brown', 'gray', 'lightblue', 'cyan', 'magenta', 'lime',
  'maroon', 'navy', 'olive', 'teal', 'aqua', 'silver', 'gold',
  'darkred', 'darkblue', 'darkgreen', 'darkorange', 'violet', 'crimson', 'orchid',
  'indigo', 'lightcoral', 'sienna', 'plum', 'lightseagreen', 'chocolate', 'coral',
  'firebrick', 'khaki', 'lavender', 'tomato', 'palegreen', 'turquoise', 'skyblue',
  'hotpink', 'wheat', 'salmon', 'darkviolet', 'forestgreen', 'darkcyan', 'lightpink',
  'dodgerblue', 'mediumseagreen', 'goldenrod', 'mediumvioletred', 'mediumorchid', 'mediumslateblue', 'springgreen',
  'slateblue', 'steelblue', 'seagreen', 'mediumturquoise', 'darkkhaki', 'cadetblue', 'mediumspringgreen', 'lightgreen'
];

function ColorDropdown({forwardColor, oldColor}) {
  const [selectedColor, setSelectedColor] = useState('darkgray');
  useEffect(() => {
    if (oldColor) {
      setSelectedColor(oldColor);
    }
  }, [oldColor]);
  const [colorView, setColorView] = useState(false);
const setColor = (color) =>{
 setSelectedColor(color);
 setColorView(false);
forwardColor(color);
}

  return (
    <div className="p-1">
      <div onClick={()=>setColorView(true)} className="flex justify-center mb-4 bg-white w-full rounded-md">
      <div className="flex flex-row" >
     <p> <FontAwesomeIcon icon={faFolder} style={{ color: selectedColor, fontSize: '2rem' }} /></p>
      <p className='mt-2 ml-2 text-md'  style={{ color: selectedColor }}>{selectedColor}</p></div>  
      </div>
    {colorView &&<div className="grid grid-cols-7 gap-2">
        {colors.map((color, index) => (
          <button
            key={index}
            className="w-8 h-8 rounded"
            style={{ backgroundColor: color }}
            onClick={()=>setColor(color)}
          />
        ))}
      </div>}  
    </div>
  );
}

export default ColorDropdown;
