import React, {useState, useEffect} from 'react'
import PhoneInput from 'react-phone-input-2';
import FileBase from 'react-file-base64';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import 'react-phone-input-2/lib/style.css';
import { createClient } from '../redux/features/clientSlice';

const initialState = {
  firstName:'',
  lastName:'',
  phone:'',
  email:'',
  birth:'',
  address:'',
  description:'',
  liar:'no'
}

const AddClient = ({goToClients})=> {
    const [countryCode, setCountryCode] = useState('');
    const [clientData, setClientData] = useState(initialState);

    const error = useSelector((state) => state.client.error);
const loading = useSelector((state) => state.client.loading);
   const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {firstName, lastName, phone, email, birth, address, description} = clientData;

    useEffect(()=>{
    error && toast.error(error)
    },[error])
    const getUserCountryCode = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          setCountryCode(data.country_code.toLowerCase()); // Postavljamo country code
        } catch (error) {
          console.error('Error fetching location data:', error);
        }
      };
      useEffect(() => {
        getUserCountryCode();
      }, []);

      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setClientData({...clientData, [name]: value})
      };
      const handleSubmit = (e) =>{
          e.preventDefault();
            const updatedClientData = {...clientData, name:user?.result?.email};
            dispatch(createClient({updatedClientData, navigate, toast}));
            goToClients();
            handleClear();
      };
      const handleClear = () =>{
        setClientData({ firstName:'', lastName:'', phone:'', email:'', birth:'', address:'', description:'', liar:'no'})
      };

  return (
<div>
        <h2 className="text-xl font-bold text-white mb-1">Add Client</h2>
        <form onSubmit={handleSubmit} className="space-y-3 w-full"  noValidate>
            <div className="flex md:flex-row flex-col w-full">
            <div className='md:w-1/3 w-full'>
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} id="firstName" name="firstName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/3 w-full'>
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} id="lastName" name="lastName" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="phone" className="block text-xs font-medium text-gray-200">Phone Number</label>
          <PhoneInput
        country={countryCode} // Setuje automatski prefiks na osnovu države
        enableSearch={true} // Omogućava pretragu država
        value={phone} // Povezivanje vrednosti sa stanjem
        onChange={(value) => setClientData({ ...clientData, phone: `+${value }`})} // Ažuriranje broja telefona
        inputStyle={{
            width: '100%',
            padding: '12px',
            borderRadius: '7px',
            border: '1px solid #ccc',
            paddingLeft:'50px',
            marginTop:'100px',
            height:'32px'
          }}
      />
      </div>
            </div>
            <div className="flex md:flex-row flex-col w-full">
          <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="email" className="block text-xs font-medium text-gray-100">Email</label>
            <input onChange={onInputChange} id="email" name="email" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
 <div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="birth" className="block text-xs font-medium text-gray-100">Date of birth</label>
            <input onChange={onInputChange} id="birth" name="birth" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
<div className='md:w-1/3 w-full mt-1'>
            <label htmlFor="address" className="block text-xs font-medium text-gray-100">Address</label>
            <input onChange={onInputChange} id="address" name="address" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>
<div className="flex md:flex-row flex-col w-full">
           <div className='md:w-2/3 w-full mt-1'>
             <label htmlFor="description" className="block text-xs font-medium text-gray-100">Description</label>
             <input onChange={onInputChange} id="description" name="description" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='md:w-1/3 mt-1'>
    <label htmlFor="liar" className="block text-xs font-medium text-gray-100">Promise-breaker</label>
    <select 
        id="liar" 
        name="liar" 
        value={clientData.liar} 
        onChange={(e) => setClientData({ ...clientData, liar: e.target.value })} 
        className="ml-3 w-1/3 mt-1 px-2 py-1 border border-gray-300 bg-white rounded-md shadow-xs focus:outline-none focus:ring-2 focus:ring-green-400 focus:border-transparent"
    >
        <option value="no">No</option>
        <option value="yes">Yes</option>
    </select>
</div>
 </div>
          <button type="submit" className="w-full flex justify-center py-1 px-2 border border-transparent rounded-md shadow-xs text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div>
  )
}

export default AddClient;