import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createService, getUserServices } from '../redux/features/serviceSlice';
import PriceInput from '../components/PriceInput';
import ColorDropdown from '../components/ColorDropdown';
import { createCategory } from '../redux/features/categorySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import Services from '../lists/Services';
import Categories from '../lists/Categories';
const initialState = {
  service:'',
  price:'',
  time:'',
}

const AddService = ({goToAddSession, categories, services})=> {
  const user = useSelector(selectUser);
  const userId = user?.result._id;
  const [category, setCategory] = useState('');
  const [serviceData, setServiceData] = useState(initialState);
  const [colorFinish, setColorFinish] = useState('darkgray');
  const {service, price, time} = serviceData;
  const [categoryView, setCategoryView] = useState(false);
  const [addNewView, setNewView] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };
const setPrice = (price) =>{
  setServiceData({...serviceData,price})
}
  const onCategoryChange = (e)=>{
    const {name, value} = e.target;
   setCategory(e.target.value)
   };
 const onInputChange = (e)=>{
  const {name, value} = e.target;
        setServiceData({...serviceData, [name]: value})
 };
 const handleSubmit = (e) =>{
  e.preventDefault();
  
  const categoryData = {colorFinish,category};
  console.log(colorFinish);
  console.log( category);
  console.log(serviceData);
  console.log( categoryData);

  if(!selectedCategory){
    e.preventDefault();
  setServiceData({...serviceData});
console.log(serviceData);
const updatedServiceData = {
  ...serviceData,
  category: categoryData.category 
};
    dispatch(createCategory({categoryData, navigate, toast}));
    dispatch(createService({updatedServiceData, navigate, toast}));
  }else{
  e.preventDefault();
  setServiceData({...serviceData,selectedCategory});
  const updatedServiceData = {
    ...serviceData,
    category: selectedCategory 
  };

    dispatch(createService({updatedServiceData, navigate, toast}));
 }
 goToAddSession();
 handleClear();
}
 const handleCategory = (e) =>{
  e.preventDefault();
  const categoryData = {colorFinish,category};
  dispatch(createCategory({categoryData, navigate, toast}));
 }
 const handleClear = () =>{
    setServiceData({service:'', price:'', time:''})
 }
 const takeColor = (color)=>{
  setColorFinish(color);
 }
 const addNew = () =>{
  setNewView(true);
 }
  return (
<div>
  <div className="flex flex-row justify-between md:justify-around">
  <h2 type="button" onClick={()=>setCategoryView(false)} className={`text-xl font-bold text-white mb-1 ${!categoryView ? 'bg-green-900 rounded-md p-1' : ''}`}>Service</h2>
  <h2 type="button" onClick={()=>setCategoryView(true)} className={`text-xl font-bold text-white mb-1 ${categoryView ? 'bg-green-900 rounded-md p-1' : ''}`}>Category</h2>
  </div>
  {categoryView && 
  <div>
  <form onSubmit={handleCategory} className="space-y-6 w-full">
        <div className="flex md:flex-row flex-col w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="category" className="block text-sm font-medium text-gray-100">Category</label>
            <input onChange={onCategoryChange} id="category" name="category" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="categoryColor" className="block text-sm font-medium text-gray-100">Choose Color of Category</label>
            <ColorDropdown forwardColor={takeColor}/>
          </div>
            </div>
          <button type="submit" className="md:w-1/2 w-full flex justify-center py-2 px-2 bor1er border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form><h5 className='text-white font-bold'>All Categories</h5>
      <Categories categories={categories} />
        </div> }
  
      {!categoryView &&<div>
       <form onSubmit={handleSubmit} className="space-y-6 w-full ">
       <div className="flex md:flex-col flex-col w-full justify-center">
       <div className='md:w-1/2 w-full'>
           <label htmlFor="service" className="block text-sm font-medium text-gray-100">Kind of Service</label>
           <input onChange={onInputChange} id="service" name="service" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
         </div>
           <div className='md:w-1/2 w-full'>
           <label htmlFor="Choose" className="block text-sm font-medium text-gray-100">Service Category</label>
          {!addNewView && 
           <select
           value={selectedCategory}
           onChange={handleSelectChange}
        className="w-full px-2 py-1 rounded-md text-center shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
      >
        <option value="" className="bg-black text-white" >Choose a category</option>
        {categories.map((item) => (
          <option style={{color:'white', backgroundColor:item.colorFinish}} key={item._id} value={item.category}>
           {item.category}
          </option>
        ))}
      </select>}
     {!addNewView && !selectedCategory &&
     <p onClick={()=>addNew()} className="mt-2 border border-black w-full px-2 py-1 font-bold rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none">
           or Add new Category +
           </p>}
      {addNewView && 
        <div className="flex md:flex-row flex-col w-full">
        <div className='md:w-1/2 w-full'>
        <label htmlFor="category" className="block text-sm font-medium text-gray-100">Add new Category</label>
        <input onChange={onCategoryChange} id="category" name="category" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
      </div>
      <div className='md:w-1/2 w-full'>
        <label htmlFor="categoryColor" className="block text-sm font-medium text-gray-100">Choose Color of Category</label>
        <ColorDropdown forwardColor={takeColor}/>
      </div>
        </div>}
         </div>
         <div className='md:w-1/2 w-full'>
           <label htmlFor="price" className="block text-sm font-medium text-gray-100">Price</label>
           <PriceInput sendPrice={setPrice} />
         </div>
         <div className='md:w-1/2 w-full'>
           <label htmlFor="time" className="block text-sm font-medium text-gray-100">Time in minutes</label>
           <input onChange={onInputChange} id="time" name="time" type="text" className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
         </div>
           </div>
         <button type="submit" className="md:w-1/2 w-full flex justify-center py-2 px-2 bor1er border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
           Submit
         </button>
       </form><h5 className='text-white font-bold'>All Services</h5>
        <Services services={services} />
        </div>  } 
      </div>
  )
}

export default AddService;