import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteArchive, getUserArchives } from '../redux/features/archiveSlice';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SessionsHistoryData from './SessionsHistoryData';
const SessionsHistory = ({goToSessions}) => {
    const [showData, setShowData] = useState();
    const [archiveData, setArchiveData] = useState();
    const sessionsHistory = useSelector((state)=>state.archive.archives);
    const user = useSelector(selectUser);
    const userId = user?.result._id;
    const dispatch = useDispatch();
    
    useEffect(()=>{
      dispatch(getUserArchives({userId}));
    },[])
    const handleDelete = (id) =>{
        if(window.confirm('Are you sure you want to delete this session?',id)){
            dispatch(deleteArchive({id, toast}))
        }
          }
          const openShowData = (id)=>{
            const selectedArchive = sessionsHistory.find(client => client._id === id);
            setArchiveData(selectedArchive); 
            setShowData(true); 
          }
  return (
    <div className="">
    <h2 className="text-xl font-bold text-white mb-1">Archived Sessions</h2>
     <div className="overflow-x-auto w-full">
       <table className="min-w-full bg-transparent rounded-lg shadow-lg">
         <thead>
           <tr className="bg-transparent text-white">
             <th className="px-2 py-1 text-left">#</th>
             <th className="px-2 py-1 text-left">Time</th>
             <th className="px-2 py-1 text-left">Full name</th>
             <th className="px-2 py-1 text-left">Action</th>

           </tr>
         </thead>
         <tbody>
           {sessionsHistory.length > 0 ? (
             sessionsHistory
             .slice() // Kreiramo kopiju da ne mutiramo originalni niz
             .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sortiranje po datumu
             .map((item, index) => (
               <React.Fragment key={index}>
                 <tr className="border-b border-transparent last:border-none">
                 <td className="px-2 text-sm text-white">{index + 1}</td>
                 <td className="px-2 text-sm text-white">{`${ new Date(item.date).toLocaleDateString('sr-RS', {
                     day: '2-digit',
                     month: 'short',
                   })}.`
                  }<br/>{item.time}</td>
                   <td className="px-2 text-sm text-white">{item.firstName} {item.lastName}</td>
                   <td className="px-2 text-sm">
                   <div className="flex flex-row space-x-4">
                    {/* <button className="text-sm hover:text-base hover:text-gray-900">
                       <Link to={`/editArchive/${item._id}`}>
                       <FontAwesomeIcon icon={faEdit} />
                       </Link>
                     </button>*/}
                     <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>handleDelete(item._id)}>
                       <FontAwesomeIcon icon={faTrash} />
                     </button>
                      <button className="text-sm hover:text-base  hover:text-gray-900" onClick={()=>openShowData(item._id)}>
                       <i >View..</i> 
                     </button>
                   </div>
                   </td>
                 </tr>
                 <tr>
                   <td colSpan="4">
                     <hr className="border-t border-white my-1" />
                   </td>
                 </tr>
               </React.Fragment>
             ))
           ) : (
             <tr>
               <td colSpan="4" className="text-center px-4 py-2 text-white">
                 No sessions found.
               </td>
             </tr>
           )}
         </tbody>
       </table>
     </div>
     {showData && (
<SessionsHistoryData sessionsHistory={archiveData}   closePopup={() => setShowData(false)}/>
)}
   </div>
  )
}

export default SessionsHistory