import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSMS, getSMS, sendSMSMessage} from '../redux/features/smsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {toast} from 'react-toastify';

const SendSMS = ({data}) => {

  const [SMSmessage, setSMSMessage] = useState('');
  const [showBox, setShowBox] = useState(false);
  const dispatch = useDispatch();


useEffect(() => {
 dispatch(getSMS());
}, [])
const sms = useSelector((state) => state.sms.sms);
console.log(sms);

  const onInputChange = (e) => {
    const { value } = e.target;
    setSMSMessage(value);
}

const createMessage = (e) => {
  e.preventDefault();
  dispatch(createSMS({ SMSmessage, toast }));
  setSMSMessage('');
}
const sendSMS = (message) =>{
  //const confirmation = confirm(`Slanje poruke ka ${customers.length} broju korisnika bih vas kostalo ${customers.length*2.2} dinara.`)
// if(confirmation){
  //  dispatch(sendSMSMessage({message, toast}))}
}
const handleDelete = () =>{}
  return (
    <div className="sms-box">
        <form onSubmit={createMessage}>
          <textarea placeholder="Create message.." value={SMSmessage} onChange={onInputChange} className='p-1 w-full h-[150px] md:h-[50px]'/>
    <button className='text-center bg-black rounded-md text-white font-bold w-full py-2 shadow-md '>
      Create the text of new message</button></form>
    <h4 className='text-white p-2 font-bold'>Created messages</h4>
  
  {sms && sms.map((smsPrint, index) =>{
      return(
      <div className='w-full flex flex-col px-1 m-1'>
      <div className='w-full flex flex-row'>
        {index+1}. 
      <p className="w-full px-1 mx-1 h-auto border border-black space-x-4 text-white" >{smsPrint.SMSmessage}
      <button className="text-sm hover:text-base text-black hover:text-gray-900 ml-2">
                        <Link to={`/editSession/${sms._id}`}>
                        <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      </button>
                      <button className="text-sm hover:text-base text-black hover:text-gray-900" onClick={()=>handleDelete(sms._id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                       <button  onClick={()=>setShowBox(true)} className="text-sm hover:text-base text-black hover:text-gray-900" title="Send SMS">
                       <FontAwesomeIcon icon={faPaperPlane} />
                      </button> </p>
      </div>
      <div className="flex flex-row float-right">
                     
                    </div>  </div>
      )
    }
   )}
  {sms.length === 0 && <p>No message from base..</p>}
  {showBox && 
  <div className="flex md:flex-row md:h-10 flex-col bg-black text-white w-full ">
     <button className='md:w-1/2 text-center'>Choose operator</button>
     <div className=" md:w-1/2 flex flex-row justify-around text-black bg-white">
      <button className=' border border-black w-full'>SMS</button>
      <button className=' border border-black w-full'>Viber</button>
      <button className=' border border-black w-full'>Email</button>
     </div>
     </div>}
    </div>
  )
}

export default SendSMS