import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Image1 from '../images/image6.jpg';
import Image2 from '../images/image2.jpg';
import Image3 from '../images/image3.jpg';
import Image4 from '../images/image4.jpg';
import Image5 from '../images/image5.jpg';
import Image6 from '../images/image1.jpg';
import Header from '../components/Header';
const images = [Image1, Image2, Image3, Image4, Image5, Image6];
const Home = () => {
  const [time, setTime] = useState('12:00');
  const handleTimeChange = ()=>{

  }
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
    {/* Main Content */}
    <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900 via-green-500 to-green-200">
      <div className="w-full max-w-6xl grid grid-cols-1 grid-rows-6 md:grid-cols-2 md:grid-rows-2 gap-4 bg-transparent rounded-lg overflow-hidden p-8">
        {/* Form with title */}
        <div className="p-8">
          <h2 className="text-3xl font-bold text-center text-white mb-6">Browse salon offers</h2>
          <form className="space-y-6 w-full">
            <div>
              <label htmlFor="city" className="block text-sm font-medium text-gray-200">Choose Your City</label>
              <input id="city" name="city" type="text" required
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            </div>
            <div>
              <label htmlFor="favor" className="block text-sm font-medium text-gray-200">Choose Your Favor</label>
              <input id="favor" name="favor" type="text" required
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            </div>
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-200">Choose Date and Time</label>
              <div className="flex md:flex-row flex-col">
                <input id="date" name="date" type="date" required
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
                <input id="time" name="time" type="time" required
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
              </div>
            </div>
            <div>
              <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
                Submit
              </button>
            </div>
          </form>
        </div>
  
        {/* Right half: 6 images */}
        <div className="grid grid-cols-3 md:grid-cols-3 gap-2 justify-center w-full">
          {[...Array(6)].slice(0, window.innerWidth < 768 ? 3 : 6).map((_, index) => {
            const skewDegree = Math.random() * 10 - 5; // Random skew between -5 and 5 degrees
            const isVertical = index % 2 === 0; // Alternating vertical/horizontal
  
            return (
              <img
                key={index}
                src={images[index]}
                alt={`Image[index]`}
                className="shadow-md object-cover md:h-48 w-[190px] h-24 transform border border-green-300"
                style={{ transform: `rotate(${skewDegree}deg)` }} // Apply random rotation
              />
            );
          })}
        </div>
      </div>
    </div>
 
  </div>
  

  )
}

export default Home